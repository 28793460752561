import StarBullet from './icons/star-bullet.png'
import Twitter from './icons/twitter.png'
import Instagram from './icons/instagram.png'
import Youtube from './icons/youtube.png'
import Facebook from './icons/facebook.png'
import Mail from './icons/mail.png'
import MailWhite from './icons/mail-white.webp'
import AboutUs from './icons/about-us.png'
import ContactUs from './icons/contact-us.png'
import X from './icons/x.png'
import XWhite from './icons/x-white.webp'
import ContactUsWhite from './icons/contact-us-white.webp'
import DesignSketch from './icons/design-sketch.png'

const icons = {
    StarBullet,
    Twitter,
    Instagram,
    Youtube,
    Facebook,
    Mail,
    AboutUs,
    ContactUs,
    X,
    XWhite,
    ContactUsWhite,
    MailWhite,
    DesignSketch


}

export default icons