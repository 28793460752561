import { useLayoutEffect } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import SplitType from 'split-type';
import { SiMaterialdesignicons } from "react-icons/si";
import { PiHandWaving } from "react-icons/pi";

gsap.registerPlugin(ScrollTrigger);

export default function ShowCTA() {

    useLayoutEffect(() => {
        gsap.to('#cta-c-1', {
            scrollTrigger: {
                trigger: '#cta-c-1',
                start: 'top bottom',
                end: 'bottom bottom',
                toggleActions: 'play none none reverse',
                markers: false // Remove markers after debugging
            },
            duration: 0.8,
            x: '0%',
            ease: 'power4.out',
            force3D: true
        });

        gsap.to('#cta-h-1', {
            scrollTrigger: {
                trigger: '#cta-h-1',
                start: 'top bottom',
                end: 'bottom bottom',
                toggleActions: 'play none none reverse',
                markers: false // Remove markers after debugging
            },
            duration: 0.8,
            x: '0%',
            ease: 'power4.out',
            force3D: true
        });

        gsap.to('#cta-c-2', {
            scrollTrigger: {
                trigger: '#cta-c-2',
                start: 'top bottom',
                end: 'bottom bottom',
                toggleActions: 'play none none reverse',
                markers: false // Remove markers after debugging
            },
            duration: 0.8,
            x: '0%',
            ease: 'power4.out',
            force3D: true
        });

        gsap.to('#cta-h-2', {
            scrollTrigger: {
                trigger: '#cta-h-2',
                start: 'top bottom',
                end: 'bottom bottom',
                toggleActions: 'play none none reverse',
                markers: false // Remove markers after debugging
            },
            duration: 0.8,
            x: '0%',
            ease: 'power4.out',
            force3D: true
        });

    }, []);

    return (
        <div className="overflow-x-hidden overflow-y-hidden">
            <section id="cta-container" className="mb-3">
                <div className="flex flex-col gap-y-3">
                    <div className="relative w-full grid sm:grid-cols-2 gap-y-4 gap-x-2">
                        <div id="cta-c-1" className="flex align-middle text-center cta-h z-[5] bg-red h-full p-6 rounded-5xl translate-x-[-100%] will-change-transform">
                            <div className="h-fit py-12 md:py-0 w-fit m-auto">
                                <h6 className="mb-4">We are experts in</h6>
                                <h3 className="m-auto text-2xl md:text-3xl text-center text-[white] font-semibold">custom designs</h3>
                            </div>
                        </div>
                        <div id='cta-h-1' className="flex gap-x-6 cta-c bg-light-black h-full z-[4] translate-x-[100%] p-6 py-12 md:p-24 rounded-5xl will-change-transform">
                            <SiMaterialdesignicons className="text-6xl md:text-7xl h-fit my-auto text-white" />
                            <h3 className="text-sm text-white leading-loose">Our design team is skilled at customizing existing designs, as well as producing stunning, award-winning brand-new designs.</h3>
                        </div>
                    </div>
                    <div className="relative w-full grid sm:grid-cols-2 gap-y-4 gap-x-2">
                        <div id="cta-c-2" className="flex align-middle text-center cta-h h-full z-[5] bg-red p-6 rounded-5xl translate-x-[-100%] will-change-transform">
                            <div className="h-fit py-12 md:py-0 w-fit m-auto">
                                <h6 className="mb-4">We are always</h6>
                                <h3 className="m-auto text-2xl md:text-3xl text-center text-[white] font-medium">happy to help</h3>
                            </div>
                        </div>
                        <div id='cta-h-2' className="flex gap-x-6 cta-c bg-light-black z-[4] h-full p-6 py-12 md:p-24 rounded-5xl translate-x-[100%] will-change-transform">
                            <PiHandWaving className="text-6xl md:text-8xl h-fit my-auto text-white" />
                            <h3 className="text-sm text-white leading-loose">We aim to be as available and supportive as we can to our clients. Feel free to use the form below or contact us directly with questions or requests.</h3>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
