import transition from "../../Utils/page-transition"

const About = () => {
    return (
        <>
            <h1>About</h1>
        </>
    )
}

export default transition(About, 'About')