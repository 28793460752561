import Lenis from "@studio-freight/lenis"


export default function FloatingCTA() {


    return (
        <>
            <a href='#contact-form' className="shadow-[0px_0px_15px_black] border-2 border-solid border-white rounded-5xl block fixed bottom-[8%] left-1/2 -translate-x-1/2 bg-[black] p-3 px-8 w-[80%] sm:w-fit z-[9999]" href="#contact-form">
                <div>
                    <p className="text-sm text-center">Connect With Us</p>
                </div>
            </a>
        </>
    )
}