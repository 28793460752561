export default function Chip({ text, variant, icon }) {

    return (
        <div className=" group w-fit h-fit hover:cursor-pointer">
            <div style={variant == 'transparent' ? { backgroundColor: 'white' } : { backgroundColor: '#202020' }} className="flex transition group-hover:-translate-y-0.5 group-hover:scale-[1] bg-[#]  w-fit p-1 px-3 rounded-2xl py-2">
                {icon != undefined &&
                    <span className="mr-1.5 h-fit my-auto">
                        {icon}
                    </span>
                }
                <p style={variant == 'transparent' ? { color: 'black', fontWeight: '700' } : { color: '#eeeeee' }} className="transition text-sm group-hover:text-white text-[#eeeeee] font-light my-auto">{text}</p>
            </div>
        </div>
    )
}