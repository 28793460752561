import icons from "../../GlobalComponents/Icons"
import transition from "../../Utils/page-transition"
import TextGoUp from '../UI/Elements/Hero/TextGoUp'
import AboutMe from "./Components/AboutMe"
import CTA from "./Components/CTA"
import Contact from "./Components/Contact"
import CustomInfo from "./Components/CustomInfo"
import Design from "./Components/Design"
import ElementsRedirection from "./Components/ElementsRedirection"
import FloatingCTA from "./Components/FloatingCTA"
import ShowCTA from "./Components/ShowCTA"
import FirstGrid from "./FirstGrid"
const Home = () => {

    return (
        <>
            <TextGoUp />
            <FirstGrid />
            <CTA />
            <ShowCTA />
            {/* <Design /> */}
            {/* <CustomInfo /> */}
            <AboutMe />
            <FloatingCTA />
        </>
    )
}

export default transition(Home, 'Home')