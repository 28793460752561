import { GiFlowerStar } from "react-icons/gi";
import SplitType from 'split-type'
import gsap from "gsap"
import { useLayoutEffect } from "react"




export default function CTA() {
    useLayoutEffect(() => {
        const text = SplitType.create('#text-anim')

        gsap.fromTo(
            text.chars,
            {
                y: 50,
                opacity: 0
            },
            {
                scrollTrigger: {
                    trigger: '#text-anim',
                    scrub: false,
                    toggleActions: 'play none none none ',
                    start: 'top 70%',
                    // markers: true

                },
                y: 0,
                opacity: 1,
                stagger: 0.03,
                duration: 1,
                ease: 'power4.out',
            }
        )
        gsap.to('.text-scroll-custominfo', {
            scrollTrigger: {
                trigger: '.text-scroll-custominfo',
                scrub: false,
                toggleActions: 'play none none none ',
                start: 'top 70%',
                // markers: true

            },
            translateY: 0,
            opacity: 1,
            duration: 1,
            ease: 'power4.out',
        }
        )
    }, [])
    return (
        <section>
            <div className="bg-[#1b1b1f] rounded-5xl p-12 px-6 md:p-24 my-3">
                <h6 className="mb-6">WHAT WE DO</h6>
                <p className="text-3xl leading-normal">We provide clients
                    with stunning <i> Crafty</i> web
                    solutions, developed
                    with <i> skill</i> & passion.</p>
            </div>
            {/* <div className=" py-3 grid grid-cols-1 md:grid-cols-2 gap-3 w-full">
                <section className=" py-12 bg-yellow  rounded-5xl">
                    <div className="pb-6">
                        <span className="uppercase text-[black] leading-relaxed text-2xl font-semibold">What makes us <span className="font-extrabold text-[black]"> Different</span> ?</span>
                    </div>

                    <div>
                        <div className="grid md:grid-cols-2 gap-6  mt-3">
                            <div className="relative overflow-hidden    border-black border-solid">
                                <div className=" my-auto h-fit flex gap-x-2" >
                                    <span className="text-xs text-[black] block origin-center mt-1.5 h-fit  animate-spin ">
                                        <GiFlowerStar />
                                    </span>
                                    <h2 className="text-md text-[black] font-normal "><span className="text-[black]  font-semibold">Custom  Design</span> Tailored For Your Website 🌐</h2>
                                </div>
                            </div>
                            <div className="relative overflow-hidden   border-black border-solid">
                                <div className=" my-auto h-fit flex gap-x-2">
                                    <span className="text-xs text-[black] block origin-center mt-1.5 h-fit  animate-spin ">
                                        <GiFlowerStar />
                                    </span>
                                    <h2 className="text-md text-[black] font-normal ">We <span className=" text-[black] font-semibold"> Craft</span> Websites That Do Not Look Like Templates 📝</h2>
                                </div>
                            </div>
                            <div className="relative overflow-hidden   border-black border-solid">
                                <div className=" my-auto h-fit flex gap-x-2">
                                    <span className="text-xs text-[black] block origin-center mt-1.5 h-fit  animate-spin ">
                                        <GiFlowerStar />
                                    </span>
                                    <h2 className="text-md text-[black] font-normal ">Grab User <span className=" text-[black] font-semibold">Attention</span>, Make Them Feel Privilidged 🤚</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="w-full border-white border-solid py-12 rounded-3xl ">
                    <div className="overflow-hidden">

                        <div className="grid grid-col gap-y-8 ">
                            <h2 id="text-anim" className=" text-5xl md:text-5xl font-bold uppercase text-center text-red mt-4">We Can Help You <span className="text-[white] "> !</span> <br /> </h2>
                        </div>
                        <div className="text-scroll-custominfo relative mt-4 opacity-0 translate-y-8">
                            <p id="text-anim-scroll" className=" font-bold uppercase text-center text-lg whitespace-nowrap">• GROW • STANDOUT • MODERNIFY • GROW • STANDOUT • MODERNIFY • GROW • STANDOUT • MODERNIFY • GROW • STANDOUT • MODERNIFY </p>
                            <div className="absolute top-0 left-0 right-0 bottom-0"></div>
                        </div>
                    </div>
                    <a className="block" >
                        <button className="px-8 py-3 rounded-3xl bg-[#202020] mx-auto w-fit block mt-8 ">Discuss Your Project Now</button>
                    </a>
                </div >

            </div> */}
        </section>
    )
}