import { gsap } from "gsap"
import ScrollTrigger from "gsap/src/ScrollTrigger"
import { useLayoutEffect } from "react"
import { useRef } from "react"
import images from "../../../../GlobalComponents/Images"
import icons from "../../../../GlobalComponents/Icons"
import { Link } from "react-router-dom"

export default function TextGoUp() {

    useLayoutEffect(() => {
        gsap.registerPlugin(ScrollTrigger)
        gsap.timeline().to('.tgu-1', {
            scrollTrigger: {
                scrub: true,
                start: 'top top',
                end: '50% 100px',
                trigger: '.tgu-container'
            },
            translateY: '-200%'
        }).to('.tgu-11', {
            scrollTrigger: {

                scrub: true,
                start: 'top top',
                end: '50% 100px',
                trigger: '.tgu-container',

            },
            delay: 0.5,
            translateY: '-200%'



        }).to('.tgu-2', {
            scrollTrigger: {
                scrub: true,
                start: '5% top',
                end: '50% 100px',
                trigger: '.tgu-container'
            },
            translateY: '-200%'
        }).to('.tgu-10', {
            scrollTrigger: {
                scrub: true,
                start: '5% top',
                end: '50% 100px',
                trigger: '.tgu-container'
            },
            translateY: '-200%'
        }).to('.tgu-3', {
            scrollTrigger: {
                scrub: true,
                start: '10% top',
                end: '50% 100px',
                trigger: '.tgu-container'
            },
            translateY: '-200%'
        }).to('.tgu-9', {
            scrollTrigger: {
                scrub: true,
                start: '10% top',
                end: '50% 100px',
                trigger: '.tgu-container'
            },
            translateY: '-200%'



        }).to('.tgu-4', {
            scrollTrigger: {
                scrub: true,
                start: '15% top',
                end: '50% 100px',
                trigger: '.tgu-container'
            },
            translateY: '-200%'
        }).to('.tgu-8', {
            scrollTrigger: {
                scrub: true,
                start: '15% top',
                end: '50% 100px',
                trigger: '.tgu-container'
            },
            translateY: '-200%'



        }).to('.tgu-5', {
            scrollTrigger: {
                scrub: true,
                start: '20% top',
                end: '50% 100px',
                trigger: '.tgu-container'
            },
            translateY: '-1000%'
        }).to('.tgu-7', {
            scrollTrigger: {
                scrub: true,
                start: '20% top',
                end: '50% 100px',
                trigger: '.tgu-container'
            },
            translateY: '-200%'
        })
            .to('.tgu-6', {
                scrollTrigger: {
                    scrub: true,
                    start: '25% top',
                    end: '50% 100px',
                    trigger: '.tgu-container',
                },
                translateY: '-200%'
            })

        //BRAND CAPTION
        // gsap.to('#make-bold', {
        //     scrollTrigger: {
        //         scrub: true,
        //         start: '15% top',
        //         end: '50% 100px',
        //         trigger: '.tgu-container',
        //         // markers: true
        //     },
        //     fontWeight: 900,
        //     scale: 1.05
        // })


        //HEADER-LOGO

        gsap.timeline().to('.tgu-head-1', {
            scrollTrigger: {
                scrub: true,
                start: '30% top',
                end: '50% 100px',
                trigger: '.tgu-container'
            },
            opacity: 1
        }).to('.tgu-head-11', {
            scrollTrigger: {

                scrub: true,
                start: '30% top',
                end: '50% 100px',
                trigger: '.tgu-container'
            },
            opacity: 1



        }).to('.tgu-head-2', {
            scrollTrigger: {
                scrub: true,
                start: '35% top',
                end: '50% 100px',
                trigger: '.tgu-container'
            },
            opacity: 1
        }).to('.tgu-head-10', {
            scrollTrigger: {
                scrub: true,
                start: '35% top',
                end: '50% 100px',
                trigger: '.tgu-container'
            },
            opacity: 1
        })



            .to('.tgu-head-3', {
                scrollTrigger: {
                    scrub: true,
                    start: '40% top',
                    end: '50% 100px',
                    trigger: '.tgu-container'
                },
                opacity: 1
            }).to('.tgu-head-9', {
                scrollTrigger: {
                    scrub: true,
                    start: '40% top',
                    end: '50% 100px',
                    trigger: '.tgu-container'
                },
                opacity: 1



            }).to('.tgu-head-4', {
                scrollTrigger: {
                    scrub: true,
                    start: '45% top',
                    end: '50% 100px',
                    trigger: '.tgu-container'
                },
                opacity: 1
            }).to('.tgu-head-8', {
                scrollTrigger: {
                    scrub: true,
                    start: '45% top',
                    end: '50% 100px',
                    trigger: '.tgu-container'
                },
                opacity: 1



            }).to('.tgu-head-5', {
                scrollTrigger: {
                    scrub: true,
                    start: '50% top',
                    end: '50% 100px',
                    trigger: '.tgu-container'
                },
                opacity: 1
            }).to('.tgu-head-7', {
                scrollTrigger: {
                    scrub: true,
                    start: '50% top',
                    end: '50% 100px',
                    trigger: '.tgu-container'
                },
                opacity: 1
            })
            .to('.tgu-head-6', {
                scrollTrigger: {
                    scrub: true,
                    start: '55% top',
                    end: '50% 100px',
                    trigger: '.tgu-container',
                },
                opacity: 1
            })


        // gsap.timeline({ repeat: -1 })
        //     .to('#x', {
        //         duration: 0.1,
        //         left: '0%',
        //         top: '0',
        //         rotateZ: '0deg'
        //     }).to('#x', {
        //         duration: 2,
        //         left: '50%',
        //         rotateZ: '90deg'

        //     }).to('#x', {
        //         duration: 2,
        //         top: '60%',
        //         left: '30%',
        //         rotateZ: '0deg'

        //     }).to('#x', {
        //         duration: 2,
        //         left: '50%',
        //         rotateZ: '90deg'
        //     }).to('#x', {
        //         duration: 2,
        //         left: '0%',
        //         top: '0',
        //         rotateZ: '0deg'
        //     })

    }, [])

    return (

        <>

            <div className="mix-blend-exclusion fixed  z-[9999] top-[50px] md:top-[70px] left-1/2 translate-x-[-50%] w-[100px]">
                <svg className=" fill-white "
                    id="Layer_1"
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 679.63 280.31"
                >
                    <defs>
                        <style dangerouslySetInnerHTML={{ __html: ".cls-1{fill:#1d1d1b;}" }} />
                    </defs>
                    <path
                        className="tgu-head-1 opacity-[0]"
                        d="M28.45,136v85.87H7.83V141.2A39.38,39.38,0,0,1,8.09,137a11.38,11.38,0,0,1,1.44-4.43,11.27,11.27,0,0,1,3.39-3.66,13.8,13.8,0,0,1,6.13-2.09,13.68,13.68,0,0,1-6.13-2.08,11.31,11.31,0,0,1-4.83-8,37.54,37.54,0,0,1-.26-4.3V31.84H28.45v85.35H40.19V31.84H60.81v80.65a37.54,37.54,0,0,1-.26,4.3,11.22,11.22,0,0,1-4.83,8,13.68,13.68,0,0,1-6.13,2.08,13.8,13.8,0,0,1,6.13,2.09,11.41,11.41,0,0,1,3.4,3.66A11.53,11.53,0,0,1,60.55,137a39.38,39.38,0,0,1,.26,4.18v80.65H40.19V136Z"
                    />
                    <path
                        className="tgu-head-2 opacity-[0]"
                        d="M97.09,136v85.87H76.47V141.2a36.9,36.9,0,0,1,.26-4.18,11.38,11.38,0,0,1,1.44-4.43,11.27,11.27,0,0,1,3.39-3.66,13.8,13.8,0,0,1,6.13-2.09,13.68,13.68,0,0,1-6.13-2.08,11.31,11.31,0,0,1-4.83-8,35.4,35.4,0,0,1-.26-4.3V31.84H97.09v85.35h11.74V31.84h20.62v80.65a37.54,37.54,0,0,1-.26,4.3,11.22,11.22,0,0,1-4.83,8,13.68,13.68,0,0,1-6.13,2.08,13.8,13.8,0,0,1,6.13,2.09,11.41,11.41,0,0,1,3.4,3.66,11.53,11.53,0,0,1,1.43,4.43,39.38,39.38,0,0,1,.26,4.18v80.65H108.83V136Z"
                    />
                    <path className="tgu-head-3 opacity-[0]" d="M145.89,31.84h20.88v190H145.89Z" />
                    <path
                        className="tgu-head-4 opacity-[0]"
                        d="M201.74,221.85l-10.17-12.53q-2.35-3.13-4-5.61a20.91,20.91,0,0,1-2.61-5.22,31.14,31.14,0,0,1-1.31-6.27,78.43,78.43,0,0,1-.39-8.48V31.84h20.62v165h11.75V31.84H236.2v151.9a78.43,78.43,0,0,1-.39,8.48,26.28,26.28,0,0,1-1.44,6.27,29.59,29.59,0,0,1-2.61,5.22q-1.56,2.47-3.92,5.61l-10.17,12.53Z"
                    />
                    <path
                        className="tgu-head-5 opacity-[0]"
                        d="M272,209.84a14,14,0,0,1-3.78,9.66,13,13,0,0,1-19.06,0,14.23,14.23,0,0,1,0-19.32,13,13,0,0,1,19.06,0A14,14,0,0,1,272,209.84Z"
                    />
                    <path
                        className="tgu-head-6 opacity-[0]"
                        d="M285,48.8a16.93,16.93,0,0,1,17-17h19.58a16.93,16.93,0,0,1,17,17V221.85H317.88V136H305.62v85.87H285Zm32.88,1.57H305.62v66.82h12.26Z"
                    />
                    <path
                        className="tgu-head-7 opacity-[0]"
                        d="M408.19,204.1q0,8.35-4.7,13.05t-13.05,4.7H372.17q-8.09,0-13.05-4.7t-5-13.05V49.59a17.34,17.34,0,0,1,5-12.79q5-4.95,13.05-5h18.27q8.35,0,13.05,5t4.7,12.79V93.44H387.57V50.37H374.78v153h12.79V136h-5V117.19h25.58Z"
                    />
                    <path
                        className="tgu-head-8 opacity-[0]"
                        d="M423.07,221.85v-190h43.32V50.37H443.68v66.82h19.06V136H443.68v67.34h22.71v18.53Z"
                    />
                    <path
                        className="tgu-head-9 opacity-[0]"
                        d="M496.41,31.84l19.31,95.53V31.84h20.36v190H517.81l-19.32-98.14v98.14H478.14v-190Z"
                    />
                    <path
                        className="tgu-head-10 opacity-[0]"
                        d="M600,217.15q-4.69,4.69-13,4.7H569.49c-5.58,0-9.92-1.57-13-4.7s-4.7-7.48-4.7-13.05V49.59q0-7.83,4.7-12.79t13-5H587q8.35,0,13,5t4.7,12.79V93.44H584.1V50.37H572.36v153H584.1V160.25h20.62V204.1Q604.72,212.46,600,217.15Z"
                    />
                    <path
                        className="tgu-head-11 opacity-[0]"
                        d="M635,221.85V163.12a19.64,19.64,0,0,1-11.88-5.48,16.34,16.34,0,0,1-4.83-12.26V31.84h20.62V144.59H652V31.84h20.62V145.38a16.34,16.34,0,0,1-4.83,12.26,19.64,19.64,0,0,1-11.87,5.48v58.73Z"
                    />
                </svg>
            </div>
            <div className="tgu-container">
                <div className="fill-white z-[-1] fixed top-[70px] left-1/2 translate-x-[-50%] overflow-hidden w-full  md:w-[60%] mx-auto">

                    <svg className=" fill-white"
                        id="Layer_1"
                        data-name="Layer 1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 679.63 280.31"
                    >
                        <defs>
                            <style dangerouslySetInnerHTML={{ __html: ".cls-1{fill:#1d1d1b;}" }} />
                        </defs>
                        <path
                            className="tgu-1"
                            d="M28.45,136v85.87H7.83V141.2A39.38,39.38,0,0,1,8.09,137a11.38,11.38,0,0,1,1.44-4.43,11.27,11.27,0,0,1,3.39-3.66,13.8,13.8,0,0,1,6.13-2.09,13.68,13.68,0,0,1-6.13-2.08,11.31,11.31,0,0,1-4.83-8,37.54,37.54,0,0,1-.26-4.3V31.84H28.45v85.35H40.19V31.84H60.81v80.65a37.54,37.54,0,0,1-.26,4.3,11.22,11.22,0,0,1-4.83,8,13.68,13.68,0,0,1-6.13,2.08,13.8,13.8,0,0,1,6.13,2.09,11.41,11.41,0,0,1,3.4,3.66A11.53,11.53,0,0,1,60.55,137a39.38,39.38,0,0,1,.26,4.18v80.65H40.19V136Z"
                        />
                        <path
                            className="tgu-2"
                            d="M97.09,136v85.87H76.47V141.2a36.9,36.9,0,0,1,.26-4.18,11.38,11.38,0,0,1,1.44-4.43,11.27,11.27,0,0,1,3.39-3.66,13.8,13.8,0,0,1,6.13-2.09,13.68,13.68,0,0,1-6.13-2.08,11.31,11.31,0,0,1-4.83-8,35.4,35.4,0,0,1-.26-4.3V31.84H97.09v85.35h11.74V31.84h20.62v80.65a37.54,37.54,0,0,1-.26,4.3,11.22,11.22,0,0,1-4.83,8,13.68,13.68,0,0,1-6.13,2.08,13.8,13.8,0,0,1,6.13,2.09,11.41,11.41,0,0,1,3.4,3.66,11.53,11.53,0,0,1,1.43,4.43,39.38,39.38,0,0,1,.26,4.18v80.65H108.83V136Z"
                        />
                        <path className="tgu-3" d="M145.89,31.84h20.88v190H145.89Z" />
                        <path
                            className="tgu-4"
                            d="M201.74,221.85l-10.17-12.53q-2.35-3.13-4-5.61a20.91,20.91,0,0,1-2.61-5.22,31.14,31.14,0,0,1-1.31-6.27,78.43,78.43,0,0,1-.39-8.48V31.84h20.62v165h11.75V31.84H236.2v151.9a78.43,78.43,0,0,1-.39,8.48,26.28,26.28,0,0,1-1.44,6.27,29.59,29.59,0,0,1-2.61,5.22q-1.56,2.47-3.92,5.61l-10.17,12.53Z"
                        />
                        <path
                            className="tgu-5"
                            d="M272,209.84a14,14,0,0,1-3.78,9.66,13,13,0,0,1-19.06,0,14.23,14.23,0,0,1,0-19.32,13,13,0,0,1,19.06,0A14,14,0,0,1,272,209.84Z"
                        />
                        <path
                            className="tgu-6"
                            d="M285,48.8a16.93,16.93,0,0,1,17-17h19.58a16.93,16.93,0,0,1,17,17V221.85H317.88V136H305.62v85.87H285Zm32.88,1.57H305.62v66.82h12.26Z"
                        />
                        <path
                            className="tgu-7"
                            d="M408.19,204.1q0,8.35-4.7,13.05t-13.05,4.7H372.17q-8.09,0-13.05-4.7t-5-13.05V49.59a17.34,17.34,0,0,1,5-12.79q5-4.95,13.05-5h18.27q8.35,0,13.05,5t4.7,12.79V93.44H387.57V50.37H374.78v153h12.79V136h-5V117.19h25.58Z"
                        />
                        <path
                            className="tgu-8"
                            d="M423.07,221.85v-190h43.32V50.37H443.68v66.82h19.06V136H443.68v67.34h22.71v18.53Z"
                        />
                        <path
                            className="tgu-9"
                            d="M496.41,31.84l19.31,95.53V31.84h20.36v190H517.81l-19.32-98.14v98.14H478.14v-190Z"
                        />
                        <path
                            className="tgu-10"
                            d="M600,217.15q-4.69,4.69-13,4.7H569.49c-5.58,0-9.92-1.57-13-4.7s-4.7-7.48-4.7-13.05V49.59q0-7.83,4.7-12.79t13-5H587q8.35,0,13,5t4.7,12.79V93.44H584.1V50.37H572.36v153H584.1V160.25h20.62V204.1Q604.72,212.46,600,217.15Z"
                        />
                        <path
                            className="tgu-11"
                            d="M635,221.85V163.12a19.64,19.64,0,0,1-11.88-5.48,16.34,16.34,0,0,1-4.83-12.26V31.84h20.62V144.59H652V31.84h20.62V145.38a16.34,16.34,0,0,1-4.83,12.26,19.64,19.64,0,0,1-11.87,5.48v58.73Z"
                        />
                    </svg>
                </div>

                <section className="pt-[35vh] sm:pt-[50vh]  md:pt-[60vh]">
                    <div className="grid md:grid-cols-2 gap-x-3 gap-y-3">
                        <div className="hover:scale-[0.98] transition bg-grey p-12 rounded-5xl relative aspect-square overflow-hidden">
                            <img id='x' className="absolute left-1/2 top-1/2 w-12 translate-x-[-50%] translate-y-[-50%]" src="/icons/x-red.png" alt="xxiv-x" />
                            <img id="xt" className="absolute left-1/2 top-1/2 w-12 translate-x-[-50%] translate-y-[-50%]" src="/icons/x-black.png" alt="xxiv-x" />
                            <img id="i" className="absolute left-1/2 top-1/2 w-12 translate-x-[-50%] translate-y-[-50%]" src="/icons/i-white.png" alt="xxiv-i" />
                            <img id="v" className="absolute left-1/2 top-1/2 w-12 translate-x-[-50%] translate-y-[-50%]" src="/icons/v-white.png" alt="xxiv-v" />
                            <div className="flex flex-col justify-between h-full">
                                <div></div>
                                <h2 className="text-2xl font-semibold h-fit">We Design Websites</h2>
                            </div>
                        </div>
                        <div className="grid grid-rows-2 gap-x-3 gap-y-3">

                            <a className=" hover:scale-[0.98] transition group" href='#our-services'>
                                <div className="relative bg-red p-12 rounded-5xl h-full flex flex-col justify-between overflow-x-hidden">
                                    <div></div>
                                    <div className="absolute top-1/2 left-0 translate-y-[-50%]"><h2 className="text-5xl opacity-0 -sm:opacity-100 group-hover:opacity-100 transition animate-services whitespace-nowrap">OUR SERVICES OUR SERVICES OUR SERVICES OUR SERVICES OUR SERVICES</h2></div>
                                    <div className="flex justify-between">
                                        <h2 className="group-hover:opacity-0 -sm:opacity-0 text-lg font-semibold h-fit mt-auto">Our Services</h2>
                                        <img className="w-6 h-6 invert" src={icons.AboutUs} alt="About Us" />
                                    </div>
                                </div>
                            </a>
                            <a className="group" href='#contact-form'>

                                <div className="hover:scale-[0.98] transition relative bg-[black] p-12 rounded-5xl h-full -md:aspect-video flex flex-col justify-between overflow-x-hidden group">
                                    <div></div>
                                    <div className="absolute top-1/2 left-0 translate-y-[-50%]"><h2 className="-sm:opacity-100 text-5xl text-white opacity-0 group-hover:opacity-100 transition animate-contact whitespace-nowrap">CONTACT US CONTACT US CONTACT US CONTACT US</h2></div>

                                    <div className="flex justify-between">
                                        <h2 className="group-hover:opacity-0 -sm:opacity-0 text-lg font-semibold h-fit mt-auto">Contact Us</h2>
                                        <img className="w-6 h-6 invert" src={icons.ContactUs} alt="Contact Us" />
                                    </div>
                                </div>
                            </a>

                        </div>
                    </div>
                </section>
                {/* 
                <section className="">
                    <h3 id="make-bold" className="h-0 font-medium text-white text-center text-xl md:text-3xl pt-[50vh]  md:pt-[70vh]"><h3 className="font-light bg-red w-fit rounded-3xl mx-auto p-4">Elevating digital visions, where <span className="text-[black]"> creativity</span> meets precision in every pixel, crafting <span className="text-[#080808]"> unforgettable </span> online experiences.</h3></h3>
                </section> */}
            </div>


        </>

    )
}